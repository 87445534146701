import { useEffect, useState } from 'react';
import axios from 'axios';

function Account() {
	const [data, setData] = useState([]);

	useEffect(() => {
		const url = 'https://api-us-west1.tatum.io/v3/ledger/account?pageSize=50';
		const headers = {
			'content-type': 'application/json',
			'x-api-key': '18e1c52e-1af2-45c2-b229-ac437181ecbc',
		};
		const fetchData = async () => {
			const result = await axios(url, { headers });
			console.log(result.data);
			setData(result.data);
		};

		fetchData();
	}, []);

	return (
		<table border={1}>
			<thead>
				<tr>
					<th>Currency</th>
					<th>ID</th>
					<th>Accounting</th>
					<th>Customer ID</th>
					<th>XPUB</th>
				</tr>
			</thead>
			<tbody>
				{data.map((item, index) => (
					<tr key={index}>
						<td>{item.currency}</td>
						<td>{item.id}</td>
						<td>{item.accountingCurrency}</td>
						<td>{item.customerId}</td>
						<td>{item.xpub}</td>
					</tr>
				))}
			</tbody>
		</table>
	);
}

export default Account;
